<template>
  <div>
    <div class="banner"
      :style="{backgroundImage: 'url(' + imgUrl+'design/home%20page/banner-about.png' + ')'}">
      <div class="container"></div>
    </div>
    <div class="feature">
      <div class="container flex flex-justify-between">
        <div class="item">
          <div class="img-wrap flex flex-align-center flex-pack-center">
            <img class="icon-1" src="../../assets/images/home/about/icon-about-1.png" />
          </div>
          <div class="content flex flex-v flex-align-center">
            <h4>设计中心</h4>
            <div>创意多快好省</div>
            <div>数万名设计师入驻</div>
            <div>需求市场无缝对接</div>
          </div>
        </div>
        <div class="item">
          <div class="img-wrap flex flex-align-center flex-pack-center">
            <img class="icon-2" src="../../assets/images/home/about/icon-about-2.png" />
          </div>
          <div class="content flex flex-v flex-align-center">
            <h4>备料中心</h4>
            <span>敬请期待</span>
            <div>亚洲最大</div>
            <div>橡胶木备料</div>
            <div>占地三万八千平</div>
            <div>年产三万方</div>
          </div>
        </div>
        <div class="item">
          <div class="img-wrap flex flex-align-center flex-pack-center">
            <img class="icon-3" src="../../assets/images/home/about/icon-about-3.png" />
          </div>
          <div class="content flex flex-v flex-align-center">
            <h4>喷涂中心</h4>
            <span>敬请期待</span>
            <div>占地32亩</div>
            <div>日产1200套</div>
            <div>共享喷涂</div>
            <div>高端工艺</div>
          </div>
        </div>
        <div class="item">
          <div class="img-wrap flex flex-align-center flex-pack-center">
            <img class="icon-4" src="../../assets/images/home/about/icon-about-4.png" />
          </div>
          <div class="content flex flex-v flex-align-center">
            <h4>企业云MES</h4>
            <div>制造全周期管控</div>
            <div>数字化生产信息</div>
            <div>生产状态全透明</div>
          </div>
        </div>
        <div class="item">
          <div class="img-wrap flex flex-align-center flex-pack-center">
            <img class="icon-5" src="../../assets/images/home/about/icon-about-5.png" />
          </div>
          <div class="content flex flex-v flex-align-center">
            <h4>物流中心</h4>
            <span>敬请期待</span>
            <div>全产业链物流信息</div>
            <div>优化调度系统</div>
            <div>物联网加持</div>
          </div>
        </div>
        <div class="item">
          <div class="img-wrap flex flex-align-center flex-pack-center">
            <img class="icon-6" src="../../assets/images/home/about/icon-about-6.png" />
          </div>
          <div class="content flex flex-v flex-align-center">
            <h4>政企服务中心</h4>
            <div>政策通知牵引</div>
            <div>政策一键申报</div>
            <div>政策批复及时回馈</div>
          </div>
        </div>
        <div class="item">
          <div class="img-wrap flex flex-align-center flex-pack-center">
            <img class="icon-7" src="../../assets/images/home/about/icon-about-7.png" />
          </div>
          <div class="content flex flex-v flex-align-center">
            <h4>金融中心</h4>
            <span>敬请期待</span>
            <div>在线支付</div>
            <div>信用数据画像</div>
            <div>无需抵押担保</div>
            <div>随借随还</div>
            <div>票据可拆分信用</div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact">
      <div class="container flex flex-pack-center">
        <div class="flex flex-align-center">
          <img class="icon-1" src="../../assets/images/home/about/btm-icon-1.png" />
          <span>免费试用</span>
        </div>
        <div class="flex flex-align-center">
          <img class="icon-2" src="../../assets/images/home/about/btm-icon-2.png" />
          <span>
            在线客服
            <div>18979787390</div>
          </span>
        </div>
        <div class="flex flex-align-center">
          <img class="icon-3" src="../../assets/images/home/about/btm-icon-3.png" />
          <span>1V1服务</span>
        </div>
        <div class="qrcode-wrap flex">
          <div class="qrcode">
            <img src="../../assets/images/home/about/qrcode-gzh.png" />
            <div>微信公众号</div>
          </div>
          <div class="qrcode">
            <img src="../../assets/images/home/about/qrcode-gzh.png" />
            <div>微信客服</div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="container">
        <div>版权所有</div>
        <div>Copyright@2020 All Rights Reserved</div>
      </div>
    </div>
  </div>
</template>
<script>
import { STATIC_URL_PRE } from '@/config';
export default {
  data() {
    return {
      imgUrl:STATIC_URL_PRE,
    }
  }
}
</script>
<style scoped lang="less">
.container {
  width: 1200px;
  margin: 0 auto;
}
.banner {
  height: 490px;
  // background: url("../../assets/images/home/banner-about.png") center center no-repeat;
  background-position: center center;
  background: cover;
}
.feature {
  padding: 30px 0 38px;
  .item {
    width: 140px;
    .img-wrap {
      width: 80px;
      height: 80px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 50%;
      margin: 0 auto;
      .icon-1 {
        width: 48px;
        height: 48px;
      }
      .icon-2 {
        width: 48px;
        height: 48px;
      }
      .icon-3 {
        width: 29px;
        height: 52px;
      }
      .icon-4 {
        width: 51px;
        height: 38px;
      }
      .icon-5 {
        width: 46px;
        height: 35px;
      }
      .icon-6 {
        width: 48px;
        height: 45px;
      }
      .icon-7 {
        width: 43px;
        height: 48px;
      }
    }
    .content {
      padding: 15px 10px 0;
      width: 140px;
      height: 192px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      margin-top: 20px;
      position: relative;
      div {
        color: #4d4d4d;
        line-height: 20px;
      }
      span {
        font-size: 10px;
        color: #ccc;
        position: absolute;
        top: 40px;
      }
      h4 {
        color: #292929;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 36px;
      }
    }
  }
}
.contact {
  .container {
    border-bottom: 1px solid #808080;
    padding-bottom: 49px;
    > div {
      margin-left: 180px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-left: 99px;
      }
      .icon-1 {
        width: 45px;
        height: 45px;
      }
      .icon-2 {
        width: 52px;
        height: 44px;
      }
      .icon-3 {
        width: 50px;
        height: 50px;
      }
      span {
        font-size: 28px;
        color: #808080;
        font-weight: bold;
        margin-left: 16px;
        position: relative;
        div {
          position: absolute;
          font-size: 18px;
          bottom: -30px;
        }
      }
    }
    .qrcode-wrap {
      .qrcode {
        margin-left: 39px;
        &:first-child {
          margin-left: 0;
        }
        img {
          width: 70px;
          height: 70px;
          margin-bottom: 4px;
        }
        div {
          color: #808080;
        }
      }
    }
  }
}
.copyright {
  padding: 23px;
  .container {
    text-align: center;
    div {
      color: #808080;
      font-weight: bold;
      &:last-child {
        font-size: 16px;
      }
    }
  }
}
</style>
